/*
=========================================
  card shape: sponsors - partners pages
=========================================
*/
.cards-shape{
    margin: 0px 0px 15px;

    .card{
        margin-bottom: 0rem;
        height: auto;
        border: none;
        justify-content: center;
        .container-img{
            height: 200px;
            position: relative;
            img{
                max-height: 100%;
                max-width: 100%;
                margin: auto;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
            }
        }
       
        .card-body {
            text-align: center;
            .card-title{
                font-size: 1.2rem;
                margin-bottom: 0rem;
                a{
                    color: #2f353a;
                }
                
            }
            .card-subtitle{
                font-size: 0.9rem;
                color: #4c4cb0;
                margin-top: 0rem;
            }
        }
    }
}

/*
=========================================
  Full and Half Transperent Buttons
=========================================
*/

.transparent-btn, .transparent-btn:not(:disabled):not(.disabled):active, .transparent-btn:not(:disabled):not(.disabled).active{
    background-color: transparent;
    border: 0;  
}

.half-transp-btn{
    background-color: transparent;
}

.transparent-btn:hover,.half-transp-btn:hover{
    background-color: transparent;
}

.transparent-btn.btn-primary, .half-transp-btn.btn-primary{
    color: #20a8d8;
}

.transparent-btn.btn-success, .half-transp-btn.btn-success{
    color: #379457;
}

.transparent-btn.btn-danger, .half-transp-btn.btn-danger{
    color: #f86c6b;
}

// .transparent-btn:not(:disabled):not(.disabled):active, .transparent-btn:not(:disabled):not(.disabled).active{

// }

/*
==================================
  Shared Custom Input type="file"
==================================
*/
.custom-file-input {
    color: transparent;
    opacity: initial;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Select one photo';
    color: black;
    display: inline-block;
    background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active {
    outline: 0;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
  }


/*
==================================
  Shared Team Persons Scss
==================================
*/

.headTeam{
    margin-bottom: 20px;
}

.team{
    margin-bottom: 15px;
    background-color: white;
    box-shadow: 1px 1px 3px 0 #00000069;
    padding: 20px;
    .allMembers{
        .teamMember{
            background-color: white;
            border-radius: 5px;
            box-shadow: 1px 1px 3px 0 #00000069;
            padding: 5px 15px;
            margin: 0px 20px 10px 20px;
            border: 1px solid #cacaca;
            .insiderContainer{
                display: flex;
                .container-img{
                    flex: 0 0 30%;
                    max-width: 30%;
                }
                .info{
                    flex: 0 0 50%;
                    max-width: 50%;
                }
                .delete{
                    cursor: pointer;
                    flex: 0 0 20%;
                    max-width: 20%;
                    align-self: center;
                    text-align: right;
                    color: #d72b2b;
                }
            }
        } 
        .teamMember:last-child{
            margin-right: 0px;
        }     
    }
       
}

.add-team.btn{
    background-color: transparent;
    border-color: #187da0;
    color: #187da0;
} 


.listOfSpeakers{
    max-height: 400px;
    overflow-y: auto;
    width: 100%;
}

.roles{
    margin-top: 20px;
}


/*
==================================
 End Of Shared Team Persons Scss
==================================
*/