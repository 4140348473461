// Here you can add other styles
.custom-drop-down-info{
    position: absolute;
    right: 3px;
}

.preview-img-container{
    width: 100%;
    height: 180px;
    margin: auto auto 15px;
    .preview-img{
        display: block;
        margin:auto;
        max-width: 100%;
        max-height: 100%;
    }
}

.form-control{
    background-color: transparent;
    border: 0px;
    border-bottom: 1px solid gray;
    border-radius: 0px;
    &:focus{
        box-shadow: none;
    }
}

.head-add-btn{
    display: inline-block;
    width: 100%;
}

.custom-btn{
    margin-bottom: 25px;
    background-color: #fdfdfd;
    min-width: 100px;
}

.second-ver{
    .custom-btn{
        margin: 20px 0px;
    }
}

.head-search{
    //background-color: white;
    h1{
        font-size: 2rem;
        margin-bottom: 0px;
    }
    .form-group{
        margin-bottom: 0px;
    }
    .form-control{
        margin-bottom: 0px;
        &:focus{
            background-color: transparent;
        }
        
    }
}
